/* SpotifyPlayer.css */

.spotify-player-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #121212;
	padding: 20px;
	color: #ffffff;
	font-family: "Circular", sans-serif;
}

.user-info {
	text-align: center;
	margin-bottom: 20px;
}

.player-card {
	background-color: #181818;
	border-radius: 10px;
	width: 100%;
	max-width: 400px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;
}

.card-header {
	font-size: 1.2em;
	margin-bottom: 10px;
	text-align: center;
	border-bottom: 1px solid white;
	width: 100%;
}

.card-body {
	text-align: center;
}

.album-cover {
	width: 100%;
	max-width: 200px;
	margin-top: 10px;
	border-radius: 10px;
}

.card-footer {
	display: flex;
	border-top: 1px solid white;
	padding-top: 20px;
	height: fit-content;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
}

.card-footer button {
	background-color: #1db954;
	border: none;
	padding: 10px 20px;
	border-radius: 20px;
	color: white;
	cursor: pointer;
	font-size: 0.9em;
	transition: background-color 0.3s;
}

.card-footer button:hover {
	background-color: #1ed760;
}

.no-music-message {
	text-align: center;
	padding: 20px;
}

.spotify-button {
	border: 2px solid #1db954;
	padding: 10px 20px;
	border-radius: 20px;
	color: #1db954;
	text-decoration: none;
	cursor: pointer;
	font-size: 0.9em;
	transition: background-color 0.3s;
}

.spotify-button:hover {
	background-color: #1aa34a; /* Darker Spotify green on hover */
	text-decoration: none;
	color: white;
}
.spotify-logo {
	width: 80px; /* Adjust width as needed */
	height: auto;
}
