.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: #181818;
	color: white;
	border-radius: 10px;
}

.navbar-logo {
	display: flex;
	align-items: center;
}

.navbar-logo img {
	width: 150px;  /* Adjust this value to make the logo the desired size */
	height: auto;
}

.navbar-logo a {
	color: white;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
}

.navbar-links a {
	margin-left: 1rem;
	color: white;
	text-decoration: none;
	font-size: 1rem;
}

.logout-button {
	margin-left: 1rem;
	background-color: transparent;
	color: white;
	border: 1px solid white;
	padding: 0.5rem 1rem;
	cursor: pointer;
	border-radius: 20px; 
	font-size: 1rem;
}

.logout-button:hover {
	background-color: #d64848;
}
