.navbar-brand img {
  width: 80px;
}

.nav {
z-index: 99 !important;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.logo-section {
  margin-bottom: 30px;
}

.logo {
  width: 350px;
}

.tagline {
  font-size: 2rem;
  margin: 20px 0;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.features-section {
  margin-bottom: 40px;
}

.features-heading {
  font-size: 1.75rem;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.feature-item {
  font-size: 1.1rem;
  margin: 10px 0;
}

.cta-section {
  margin-bottom: 50px;
}

.cta-heading {
  font-size: 2rem;
  margin-bottom: 10px;
}

.cta-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.login-section {
  width: 100%;
  max-width: 400px;
}
