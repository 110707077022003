button:hover {
	color: white;
	background-color: #1ed760;
}

button {
	color: white;
	margin-left: 1rem;
	background-color: transparent;
	color: white;
	border: 1px solid white;
	padding: 0.5rem 1rem;
	cursor: pointer;
	border-radius: 20px;
	font-size: 1rem;
}
