.App {
	font-family: "Circular", sans-serif;
	background-color: rgb(18, 18, 18);
	color: white;
	text-align: center;
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Full viewport height */
}

.App-header {
	padding: 20px;
}

.main-content {
	padding: 20px;
}
